/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { HomeQuery } from '../graphqlTypes'
import { useTranslation } from 'react-i18next'
import DonateNowButton from './DonateNowButton'
import React from 'react'

type Props = {
  data: HomeQuery
}

const HomeHero: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  const { heroImage } = data
  return (
    <React.Fragment>
      <BackgroundImage
        id="home__hero"
        style={{
          backgroundSize: '',
          backgroundPosition: '',
        }}
        sx={{
          py: [0, 37],
          px: [28, 28, 28, 40],
          maxWidth: '1188px',
          height: [636, 480],
          margin: 'auto',
          overflow: 'hidden',
          borderRadius: [0, 0, 0, 40],
          '&::before, &::after': {
            backgroundPositionX: 'right',
            backgroundPositionY: ['bottom'],
            backgroundSize: ['160%', 'cover']
          },
        }}
        fluid={heroImage?.childImageSharp?.fluid}
        
        backgroundColor="#F4F7F8"
      >
        <div
          sx={{
            flex: 1,
            maxWidth: ['100%', , 1440 - 206 * 2],
            mx: 'auto',
            height: [, '100%', '100%'],
            fontSize: [34, , 44],
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            id="HomeHero__ContentCard"
            sx={{
              width: ['100%', 432, 432],
              py: [20, , , 0],
              px: [20, , 15, 0],
              borderRadius: 14,
              color: ['unset', 'white', 'white', 'white'],
              bg: ['white', 'unset', 'unset', 'unset'],
            }}
          >
            <div
              sx={{
                fontWeight: 600,
                fontSize: [34, , 44],
                lineHeight: 1.2,
                mt: 0,
                mb: 0,
              }}
            >
              {t('homeHero1')}
            </div>
            <div
              sx={{
                fontFamily: 'Lato Medium',
                fontSize: [34, , 44],
                lineHeight: 1.2,
              }}
            >
              {t('homeHero2')}
            </div>
            <div
              sx={{
                fontFamily: 'Lato Medium',
                fontSize: [34, , 44],
                lineHeight: 1.2,
              }}
            >
              {t('homeHero3')}
            </div>
            <p
              sx={{
                mt: 12,
                fontSize: [18, , 20],
                mb: 0,
                lineHeight: '28px',
              }}
            >
              {t('homeHero4')}
            </p>
            <DonateNowButton
              sx={{
                mt: 16,
                display: 'inline-block',
                color: ['white', 'primary', 'primary', 'primary'],
                bg: ['primary', 'white', 'white', 'white'],
                '&:hover': {
                  backgroundColor: ['#fff', 'primary', 'primary', 'primary'],
                  color: ['primary', '#fff', '#fff', '#fff'],
                },
              }}
            />
          </div>
        </div>
      </BackgroundImage>
    </React.Fragment>
  )
}

export default HomeHero

export const query = graphql`
  fragment HomeHero on Query {
    heroImage: file(relativePath: {eq: "home-hero-gaza.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
