/** @jsx jsx */
import {jsx} from 'theme-ui'
import {HomeQuery} from '../graphqlTypes'
import Img from 'gatsby-image'
import {graphql} from 'gatsby'
import {useTranslation} from 'react-i18next'

type Props = {
  data: HomeQuery
}

const HomeMap: React.FC<Props> = ({data}) => {
  const {homeMap} = data
  const {t} = useTranslation()
  return (
    <section
      id="home__map"
      sx={{
        mt: [100, , 150],
        width: ['100%', , 846],
        mx: 'auto',
        textAlign: 'center',
      }}
    >
      <h2
        sx={{
          my: 0,
          fontSize: 24,
          fontWeight: 400,
        }}
      >
        {t('homeMapTitle')}
      </h2>
      <Img fluid={homeMap.childImageSharp.fluid} />
    </section>
  )
}

export default HomeMap

export const query = graphql`
  fragment HomeMap on Query {
    homeMap: file(relativePath: {eq: "home-map.png"}) {
      childImageSharp {
        fluid(maxWidth: 846) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
