/** @jsx jsx */
import {jsx} from 'theme-ui'
import Feature from './Feature'
type Props = {
  features: Array<{
    icon: string
    numbers: string
    text: string
  }>
}

const HomeFeatures: React.FC<Props> = ({features}) => {
  return (
    <section
      id="home__features"
      sx={{
        mt: [50, , 100],
        display: 'flex',
        mx: 'auto',
        width: ['95%', , 1014 + 40],
        maxWidth: '90%',
        flexDirection: ['column', , 'row'],
        justifyContent: 'space-between',
      }}
    >
      {features.map((feature) => {
        return <Feature key={feature.text} {...feature} />
      })}
    </section>
  )
}

export default HomeFeatures
