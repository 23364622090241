/** @jsx jsx */
import CTA from 'components/CTA'
import HomeCycles from 'components/HomeCycles'
import HomeFeatures from 'components/HomeFeatures'
import HomeHero from 'components/HomeHero'
import HomeMap from 'components/HomeMap'
import HomeProjects from 'components/HomeProjects'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {graphql, PageProps} from 'gatsby'
import {jsx} from 'theme-ui'
import featureImg1 from 'images/feat-rameau.svg'
import featureImg2 from 'images/feat-flag.svg'
import featureImg3 from 'images/feat-world.svg'
import featureImg4 from 'images/feat-man.svg'
import {HomeQuery} from '../graphqlTypes'
import {useTranslation} from 'react-i18next'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'
import {PageContext} from 'gatsbyTypes'

interface IndexPage extends PageProps<HomeQuery, PageContext> {}

const IndexPage = ({data, pageContext}: IndexPage) => {
  const {t} = useTranslation()
  useAutoSelectLanguage(pageContext)
  return (
    <Layout
      sx={{
        pb: [0, 40, 163],
      }}
      navMobileColor="#F4F7F8"
    >
      <SEO title={t('SEOHomeTitle')} description={t('SEODescription')} />
      <HomeHero data={data} />
      <HomeFeatures
        features={[
          {
            icon: featureImg1,
            numbers: '2000+',
            text: t('Projects completed'),
          },
          {
            icon: featureImg2,
            numbers: '14',
            text: t('Countries'),
          },
          {
            icon: featureImg3,
            numbers: '03',
            text: t('Continents'),
          },
          {
            icon: featureImg4,
            numbers: '50,000+',
            text: t('Beneficiaries'),
          },
        ]}
      />
      <HomeCycles />
      <HomeProjects data={data} />
      <HomeMap data={data} />
      <CTA />
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query Home($langKey: String!) {
    ...HomeHero
    ...HomeProjects
    ...HomeMap
  }
`
