/** @jsx jsx */
import cycleImg1 from 'images/home-cycle-1.svg'
import cycleImg2 from 'images/home-cycle-2.svg'
import cycleImg3 from 'images/home-cycle-3.svg'
import {jsx} from 'theme-ui'
import {useTranslation} from 'react-i18next'

type Props = {}

const HomeCycles: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <section
      id="home__cycle"
      sx={{
        mt: 80,
        width: ['100%', '90%', 1188],
        borderRadius: [, , 40],
        bg: 'lightGrey',
        textAlign: 'center',
        mx: 'auto',
        pt: 56,
        pb: 100,
      }}
    >
      <h2 sx={{my: 0, fontWeight: 400, fontSize: 24}}>
        {t('UMMATY Virtuous Cycle')}
      </h2>
      <div
        sx={{
          mt: [, , 58],
          mx: 'auto',
          width: ['100%', , 1028],
          maxWidth: '100%',
          display: 'flex',
          flexDirection: ['column', , 'row'],
          justifyContent: 'space-between',
        }}
      >
        {[
          {
            title: t('You'),
            text: t('Make a generous donation'),
            icon: cycleImg1,
          },
          {
            title: t('We'),
            text: t('Execute the projects and deliver the Amanah'),
            icon: cycleImg2,
          },
          {
            title: t('They'),
            text: t('Benefit from actions that change their life'),
            icon: cycleImg3,
          },
        ].map(({title, text, icon}, index) => {
          return (
            <div
              key={index}
              sx={{
                mt: [40, , 0],
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                sx={{
                  p: '16px 21px',
                  bg: 'white',
                  borderRadius: 9999,
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                {index + 1}
              </div>
              <div
                sx={{
                  mt: 19 + 25,
                  bg: 'white',
                  width: 236,
                  // height: 197,
                  px: 32,
                  pt: 22,
                  pb: 15,
                  borderRadius: 16,
                  boxShadow: '0px 34px 64px #EFF3F4',
                  position: 'relative',
                  '::before': {
                    content: '""',
                    position: 'absolute',
                    width: 31,
                    height: 31,
                    bg: 'white',
                    borderRadius: 4,
                    top: -12,
                    left: '50%',
                    transform: 'translateX(-50%) rotate(-45deg)',
                  },
                }}
              >
                <h3
                  sx={{
                    my: 0,
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                >
                  {title}
                </h3>
                <p
                  sx={{
                    color: 'soft',
                    mt: 8,
                    mb: 20,
                    fontSize: 16,
                  }}
                >
                  {text}
                </p>
                <img src={icon} alt="" />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default HomeCycles
