/** @jsx jsx */
import {jsx} from 'theme-ui'
import ArrowInCircle from 'images/components/ArrowInCircle.svg'
import {MouseEvent} from 'react'

type Props = {
  className?: string
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  reverse?: boolean
}

const ArrowInCircleButton: React.FC<Props> = ({reverse = false, onClick, className}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        outline: 0,
        bg: 'transparent',
        border: 'none',
      }}
    >
      <ArrowInCircle
        sx={{
          transform: reverse ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </button>
  )
}

export default ArrowInCircleButton
